@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: 'Montserrat', sans-serif;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.form-content {
  @apply w-full flex flex-col items-center justify-center space-y-8;
}
.GFG {
  text-shadow: -1px 1px 0 rgba(255, 255, 255, 0.5),
    1px 1px 0 rgba(255, 255, 255, 0.5), 1px -1px 0 rgba(255, 255, 255, 0.5),
    -1px -1px 0 rgba(255, 255, 255, 0.5);
}

ul li::marker {
  color: red;
}

.editorClass {
  /* border: solid indigo 1px; */
  padding: 0 0.5rem;
  min-height: 50vh;
}

.toolbarEditor {
  z-index: 1;
}

input[type="file" i] {
  border-radius: 100px;
  padding: 15px;
  border-width: 1px;
  @apply flex flex-col justify-center items-center text-center
}

input[type=file]::file-selector-button {
  @apply flex text-center w-full flex-col justify-center items-center bg-main-blue text-white rounded-full;
}

input[type=file]::file-selector-button:hover {
  background-color: #222C5F;
  border: 2px solid #222C5F;
}

/* * custom keyFrames */
@keyframes fadeIn {
  from {
    transform: scale(0)
  } to {
    transform: scale(1)
  }  
}

@keyframes fadeOut {
  from {
    transform: scale(1)
  } to {
    transform: scale(0)
  }
}

.fadeIn {
  animation: fadeIn 0.3s ease forwards;
}

.fadeOut {
  animation: fadeOut 0.3s ease forwards;
}

/* * loader */
.loaderRectangle {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0 3px;
 }
 
 .loaderRectangle div {
  width: 10px;
  height: 16px;
  animation: .9s ease-in-out infinite;
  background: #183153;
  box-shadow: 0 0 20px rgba(18, 31, 53, 0.3);
 }
 
 .loaderRectangle div:nth-child(1) {
  animation-name: rectangleOneAnim;
  animation-delay: 1s;
 }
 
 @keyframes rectangleOneAnim {
  0% {
   height: 15px;
  }
 
  40% {
   height: 30px;
  }
 
  100% {
   height: 15px;
  }
 }
 
 .loaderRectangle div:nth-child(2) {
  animation-name: rectangleTwoAnim;
  animation-delay: 1.1s;
 }
 
 @keyframes rectangleTwoAnim {
  0% {
   height: 15px;
  }
 
  40% {
   height: 40px;
  }
 
  100% {
   height: 15px;
  }
 }
 
 .loaderRectangle div:nth-child(3) {
  animation-name: rectangleThreeAnim;
  animation-delay: 1.2s;
 }
 
 @keyframes rectangleThreeAnim {
  0% {
   height: 15px;
  }
 
  40% {
   height: 50px;
  }
 
  100% {
   height: 15px;
  }
 }
 
 .loaderRectangle div:nth-child(4) {
  animation-name: rectangleFourAnim;
  animation-delay: 1.3s;
 }
 
 @keyframes rectangleFourAnim {
  0% {
   height: 15px;
  }
 
  40% {
   height: 40px;
  }
 
  100% {
   height: 15px;
  }
 }
 
 .loaderRectangle div:nth-child(5) {
  animation-name: rectangleFiveAnim;
  animation-delay: 1.4s;
 }
 
 @keyframes rectangleFiveAnim {
  0% {
   height: 15px;
  }
 
  40% {
   height: 30px;
  }
 
  100% {
   height: 15px;
  }
 }